import digitalResume from "../pictures/digitalResume.jpeg";
import countDownSystem from "../pictures/countDownSystem.jpeg";
import contactWebsite from "../pictures/contactWebsite.jpeg";
import myPortfolio from "../pictures/myPortfolio.jpeg";
import simulatingPicture from "../pictures/simulatingPicture.jpeg";
import liveStreaming from "../pictures/liveStreaming.jpeg";
import ticTacToe from "../pictures/ticTacToe.jpeg";
import image_gallery from "../pictures/image_gallery.jpeg";
import draggame2 from "../pictures/draggame2.jpeg";
import noam from "../pictures/noam.jpeg";
import weatherapp from "../pictures/weatherapp.jpeg";
import webserver from "../pictures/webserver.jpeg";
const react='React JS';
const HTML5_CSS3='HTML5, CSS3.';
const JavaScrpit_HTML5_CSS3='JavaScrpit, HTML5, CSS3.'

const data = [
{
    hebrew_name:"מערכת ניהול לספירה יורדת",
    english_name:"Count Down System", 
    picture: countDownSystem,
    hebrew_descrition:"פיתוח אפליקציית אינטרנט מקומית בזמן אמת עבור ארגון ביטחוני. מטרת המערכת הינה לתכנן ולפקח אחר הליך ביטחוני מסווג.",
    english_description:"Developing a real-time local web application for a security organization. Its purpose is to plan and monitor a classified security procedure.",
    frontend: react +", React-Redux, "+ JavaScrpit_HTML5_CSS3,
    backend:"Node JS, Express JS, Socket.io, MongoDB.",
    git:"https://github.com/sharonNissanov/CountDownSystem",
    message:"whatsapp://send?text=Hi Sharon, I would like to contact you regarding your 'Count Down System' project.  &phone=+972525496864",
    hebrew_message:"whatsapp://send?text= היי שרון, אשמח לדבר איתך בנוגע לפרויקט 'מערכת ניהול לספירה יורדת'.  &phone=+972525496864"
},
{
    hebrew_name:"דימוי תמונה",
    english_name:"Simulate an image", 
    picture: simulatingPicture,
    link: "https://simulate-an-image.web.app",
    frontend:"React JS, Material-UI, "+HTML5_CSS3,
    backend:"Firebase.",
    hebrew_descrition:"  התבקשתי לדמות תמונה ספציפית באמצעות שימוש בספריית העיצוב- Material-UI.",
    english_description:"Simulate a specific image by using Material-UI framework",
    git:"https://github.com/sharonNissanov/Simulate-an-image-MaterialUI",
    message:"whatsapp://send?text=Hi Sharon, I would like to contact you regarding your 'Simulate an image' project.  &phone=+972525496864",
    hebrew_message:"whatsapp://send?text= היי שרון, אשמח לדבר איתך בנוגע לפרויקט 'דימוי תמונה'.  &phone=+972525496864"
},
{
    hebrew_name:"משחק גרירה עם התניות",
    english_name:"Drag and Drop Game", 
    picture: draggame2,
    link: "https://drag-and-drop-game.herokuapp.com/",
    frontend:JavaScrpit_HTML5_CSS3,
    backend:"Heroku.",
    hebrew_descrition:"פיתוח משחק גרירה יחודי שמטרתו לצבור 10 נקודות ב-3 דקות, הוראות נוספות תוכלו למצוא בגיטהאב.",
    english_description:"Developing a unique drag and drop game. The goal is to score 10 points in 3 minutes, more instructions can be found by clicking on the GitHub icon.",
    git:"https://github.com/sharonNissanov/drag_and_drop_game",
    message:"whatsapp://send?text=Hi Sharon, I would like to contact you regarding your drag and drop game.  &phone=+972525496864",
    hebrew_message:"whatsapp://send?text= היי שרון, אשמח לדבר איתך בנוגע למשחק הגרירה שפיתחת '.  &phone=+972525496864"
},
{
    hebrew_name:"יצירת שרת HTTP",
    english_name:"Developing HTTP server with Node JS", 
    picture: webserver,
    frontend:JavaScrpit_HTML5_CSS3,
    backend:"Node JS.",
    hebrew_descrition:"פיתוח שרת HTTP באמצעות Node JS, המציג קבצי HTML ו-CSS ובעזרתו הלקוח שולח לצד השרת מידע הנשמר בקובץ data.json.",
    english_description:"Creating a HTTP server that serves the HTML & CSS and sending the information from the frontend to the backend and persisting it-by saving all the data in the 'data.json' file.",
    git:"https://github.com/sharonNissanov/Web-server",
    message:"whatsapp://send?text=Hi Sharon, I would like to contact you regarding your 'Developing HTTP server with Node JS' project.  &phone=+972525496864",
    hebrew_message:"whatsapp://send?text= היי שרון, אשמח לדבר איתך בנוגע לפרויקט 'יצירת שרת HTTP'.  &phone=+972525496864"
},


{
    hebrew_name:"פיתוח העתק של אתר קיים",
    english_name:"Developing a copy of an existing site", 
    picture: noam,
    link: "https://copy-site1.herokuapp.com/",
    frontend:"Bootstrap, "+ JavaScrpit_HTML5_CSS3,
    backend:"Heroku.",
    hebrew_descrition:" פיתוח העתק של אתר רספונסיבי קיים באמצעות Bootstrap, בגיטהאב תוכלו לראות תמונות של האתר המקורי.",
    english_description:"Develop a copy of an existing responsive site by using Bootstrap, click on the GitHub icon to see images of the original site.",
    git:"https://github.com/sharonNissanov/Responsive-page-development",
    message:"whatsapp://send?text=Hi Sharon, I would like to contact you regarding your 'Development of a copy of an existing site' project.  &phone=+972525496864",
    hebrew_message:"whatsapp://send?text= היי שרון, אשמח לדבר איתך בנוגע לפרויקט 'פיתוח העתק של אתר קיים'.  &phone=+972525496864"
},
{
    hebrew_name:"Live Streaming -שידור חי",
    english_name:"Live Streaming", 
    picture: liveStreaming,
    site:"https://www.live-rates.com/rates",
    description1:"This app displays data from: " ,
    description2:"and deals with the live streaming aspect and 3 API requests per hour or the hour limitation issue. The data displays in 2 different ways.",
    description_hebrew1:"התבקשתי לפתח אפליקציה המציגה נתונים מהאתר: ",
    description_hebrew2:"ועוסקת בהיבט השידור החי וב-3 בקשות API לשעה או בנושא הגבלת השעה. המידע מוצג בשתי דרכים שונות.",
    frontend:"React JS, Semantic-UI, "+JavaScrpit_HTML5_CSS3,
    git:"https://github.com/sharonNissanov/LIVE-STREAMING",
    message:"whatsapp://send?text=Hi Sharon, I would like to contact you regarding your 'Live Streaming' project.  &phone=+972525496864",
    hebrew_message:"whatsapp://send?text= היי שרון, אשמח לדבר איתך בנוגע לפרויקט 'Live Streaming -שידור חי'.   &phone=+972525496864"
},
{
    hebrew_name:"תיק העבודות שלי",
    english_name:"My Portfolio",
    hebrew_descrition:"פיתוח האתר הרספונסיבי השני שלי שמציג קצת עלי, על כישורי ופרוייקטים שפיתחתי. בנוסף, קורות החיים שלי מוצגים באתר וניתן להורידם. כל הנתונים מוצגים באנגלית ובעברית.",
    english_description:"Developing my second responsive website which displays a bit about me, my skills, my work and my resume and also download it. All the data presents both in English and Hebrew. ",
    picture: myPortfolio, 
    frontend:"React JS, Semantic-UI, Material-UI, "+JavaScrpit_HTML5_CSS3,
    backend:"Firebase.",
    link: "https://sharon-nissanov.com/",
    git:"https://github.com/sharonNissanov/Portfolio",
    message:"whatsapp://send?text=Hi Sharon, I would like to contact you regarding your 'My Portfolio' project.  &phone=+972525496864",
    hebrew_message:"whatsapp://send?text= היי שרון, אשמח לדבר איתך בנוגע לפרויקט 'תיק העבודות שלי'.  &phone=+972525496864"
},
{
    hebrew_name:"קורות חיים דיגיטלים",
    english_name:"Digital Resume", 
    picture: digitalResume,
    hebrew_descrition:"פיתוח אתר רספונסיבי לסטודנט שנה רביעית להנדסת חשמל ואלקטרוניקה המציג את כישוריו ואת קורות החיים שלו בעברית ובאנגלית ומאפשר ליצור איתו קשר באמצעות דואר, קישור וטלפון. המטרה העיקרית של אתר זה הייתה להנגיש את קורות חייו בתהליך חיפוש העבודה.",
    english_description:"Developing a responsive website for a fourth year student for Electrical & Electronics Engineering which displays his skills and his resume in Hebrew and English and allows contacting him via mail, linkedin and phone. The main goal of this website was to make his resume accessible in the job search process. ",
    frontend:"ReactJS, Semantic-UI, "+JavaScrpit_HTML5_CSS3,
    backend:"Firebase.",
    git:"https://github.com/sharonNissanov/Student-Resume--Gal-" ,
    link:"https://gal-wainberg.com/",
    message:"whatsapp://send?text=Hi Sharon, I would like to contact you regarding your digital resume project.  &phone=+972525496864",
    hebrew_message:"whatsapp://send?text= היי שרון, אשמח לדבר איתך בנוגע לפרויקט 'קורות חיים דיגיטלים'.  &phone=+972525496864"
},
{
    hebrew_name:"אפליקציית מזג אוויר",
    english_name:"Weather App",
    picture: weatherapp, 
    hebrew_descrition:"שימוש ב- react js ו- OpenWeather API לפיתוח אפליקציית מזג אוויר בה תוכלו להזין את המיקום המבוקש ולקבל את מזג האוויר . ",
    english_description:"Using react js and OpenWeather API to develop a weather app where you can enter the wanted location and get its weather.",
    frontend: react+", "+JavaScrpit_HTML5_CSS3,
    backend:"Firebase.",
    git:"https://github.com/sharonNissanov/weather-app",
    link:" https://weather-app-1ed48.web.app/",
    message:"whatsapp://send?text=Hi Sharon, I would like to contact you regarding your 'Weather App' project.  &phone=+972525496864",
    hebrew_message:"whatsapp://send?text= היי שרון, אשמח לדבר איתך בנוגע לפרויקט 'אפליקציית מזג אוויר'.  &phone=+972525496864"
},
{
    hebrew_name:"משחק איקס-עיגול",
    english_name:"Tic-Tac-Toe game", 
    picture:ticTacToe ,
    hebrew_descrition:"פיתוח משחק איקס-עיגול, תהנו :) ",
    english_description:"Developing a Tic-Tac-Toe game, enjoy :). ",
    frontend:react+", "+JavaScrpit_HTML5_CSS3,
    backend:"Firebase.",
    git:"https://github.com/sharonNissanov/Tic-Tac-Toe-Game" ,
    link:"https://tic-tac-toe-f0f98.web.app/",
    message:"whatsapp://send?text=Hi Sharon, I would like to contact you regarding your Tic-Tac-Toe game.  &phone=+972525496864",
    hebrew_message:"whatsapp://send?text= היי שרון, אשמח לדבר איתך בנוגע למשחק איקס-עיגול'.  &phone=+972525496864"
},
{
    hebrew_name:" גלריית תמונות אינסופית",
    english_name:"Infinite scroll image gallery",
    picture: image_gallery, 
    hebrew_descrition:"שימוש ב-Unsplash API על מנת להציג אינסוף תמונות רנדומליות. ",
    english_description:"Using Unsplash API to present random images.",
    frontend:react+", "+JavaScrpit_HTML5_CSS3,
    backend:"Firebase.",
    git:"https://github.com/sharonNissanov/Infinite-scroll-image-gallery-/tree/main/using_api",
    link:"https://unsplash-images-63f54.web.app/",
    message:"whatsapp://send?text=Hi Sharon, I would like to contact you regarding your 'Infinite scroll image gallery' project.  &phone=+972525496864",
    hebrew_message:"whatsapp://send?text= היי שרון, אשמח לדבר איתך בנוגע לפרויקט 'אתר ליצירת קשר'.  &phone=+972525496864"
},
{
    hebrew_name:"אתר ליצירת קשר",
    english_name:"Contact Website",
    picture: contactWebsite, 
    hebrew_descrition:"פיתוח האתר הרספונסיבי הראשון שלי, שמטרתו להציג את הכישורים שלי, להוריד את קורות החיים שלי וליצור איתי קשר. כל הנתונים מוצגים באנגלית ובעברית.",
    english_description:"Developing my first responsive website, which meant to show my skills, download my resume and for contacting me. All the data presents both in English and Hebrew. ",
    frontend:react+", "+"Semantic-UI, "+JavaScrpit_HTML5_CSS3,
    backend:"Firebase.",
    git:"https://github.com/sharonNissanov/My-Portfolio",
    link:"https://porfolio-d170d.web.app/",
    message:"whatsapp://send?text=Hi Sharon, I would like to contact you regarding your 'Contact Website' project.  &phone=+972525496864",
    hebrew_message:"whatsapp://send?text= היי שרון, אשמח לדבר איתך בנוגע לפרויקט 'אתר ליצירת קשר'.  &phone=+972525496864"
},



]
  export default data;